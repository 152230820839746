<template>
  <div>
    <div v-if="!showCashDetail" class="tb-cash">
      <Head :headInfo="headInfo" @headBack="goBack" @headRule="handleDialogClick('rule')"></Head>
      <div v-if="!isScroll" class="packet-fixed" @click="handleDialogOperate('cash')">
        <img src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/cash_gift_fixed.png">
      </div>
      <div v-if="!isScroll" class="head">
        <img src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/cash_gift_head.png" alt="">
        <div class="head-content" @click="jumpCashDetails">
          <p class="my-cash"><span>我的补贴红包</span>
            <van-icon name="arrow"/>
          </p>
          <p class="cash-price"><span>{{ priceValue > 0 ? priceValue : '?' }}</span> 元</p>
          <p class="cash-desc">补贴红包仅限以下商品使用</p>
        </div>
      </div>
      <div v-else class="head-scroll">
        <p>补贴红包<span>{{ priceValue }}</span>元</p>
      </div>
      <div v-if="zoneList.length > 0" :class="[isScroll ? 'zone-fixed' : '', 'cash-zone']">
        <template v-for="(item, index) in zoneList">
          <div :key="index" :class="[item.active ? 'zone-active' : 'zone-item']" @click="handleChangeTab(index)">
            <p>{{ item.value }}元</p>
            <p>专区</p>
          </div>
        </template>
      </div>
      <div v-else class="default">
        <img src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/purchase_goods_none.png">
      </div>
      <div class="goods-list">
        <template v-for="item in goodsList">
          <div class="goods-wrap" :index="item.itemId" @click="handleJumpGoodsDetails(item)">
            <img class="goods-image" v-lazy="item.pictUrl">
            <div class="goods-title">
              <p>
                <img :style="{width:item.itemSource == 3 || item.itemSource == 4 ? '35px' : '30px'}" :src="item.logo">
                {{ item.itemTitle }}
              </p>
            </div>
            <div class="goods-discounts">
              <div class="goods-coupon" v-if="item.couponValue">
                <span>{{ item.couponValue }}元券</span>
              </div>
              <div v-if="item.bonusValue > 0" class="goods-cashback">
                <p><span>补贴</span>{{ item.bonusValue }}元</p>
              </div>
            </div>
            <div class="goods-price-volume">
              <div class="price">¥ {{ item.reservePrice }}</div>
            </div>
            <div class="goods-promotion-volume">
              <div>
                <span class='money-symbol'>¥ </span>
                <span class="goods-current-price">{{ item.zkFinalPrice }}</span>
              </div>
            </div>
          </div>
        </template>
      </div>
      <van-dialog v-model="dialogVisible" :showConfirmButton="false"
                  style="background-color: transparent;">
        <div v-if="dialogType == 'rule'" class="wrapper">
          <p>活动规则</p>
          <div class="content">
            <p>
              1、新人补贴红包膨胀机制说明：每邀请一位好友加入鲸选师，补贴红包可立即膨胀50元；补贴红包膨胀无上限，邀请越多，膨胀越多；
              <br/>
              <br/>
              2、专区解锁机制说明：不同专区存在对应的准入门槛；用户首次膨胀红包至30元后，即可购买30元专区中的补贴商品；当用户的补贴红包达到一定数量时，即可开通对应的活动专区；
              <br/>
              <br/>
              补贴红包金额与专区补贴资格的关系如下：
              <br/>
              <br/>
              ⑴补贴红包≥100元，开通100元专区补贴资格；
              <br/>
              <br/>
              ⑵补贴红包≥200元，开通200元专区补贴资格；
              <br/>
              <br/>
              ⑶补贴红包≥300元，开通300元专区补贴资格；
              <br/>
              <br/>
              ⑷补贴红包≥500元，开通京东千元内购福利专区补贴资格；
              <br/>
              <br/>
              3、补贴活动领取的淘礼金有效使用时间为24小时，领取后即可在购买对应的商品时直接抵扣；
              <br/>
              <br/>
              4、在法律允许的范围内，根据活动的进展情况，鲸选师可能对活动的规则/条款作出适当修改/调整。认。②当您购买我们的鲸选省钱卡会员后，您选择申请退款，退款成功后，您的话费券领取资格将失效，请知悉。
              <br/>
              <br/>
              5、在法律允许的范围内，根据活动的进展情况，鲸选师可能对活动的规则/条款作出适当修改/调整。
              <br/>
              <br/>
              以下权益专属优惠，仅在支付会员费成为鲸选省钱卡会员后方可享有，均在鲸选师APP内使用。
            </p>
          </div>
          <div class="lottery-close">
            <van-icon name="close" @click="handleCloseDialog('rule')"/>
          </div>
        </div>
        <div v-else-if="dialogType == 'buy'" class="wrapper">
          <div class="buy-confirm">
            <p class="title">消耗{{ goodsInfo.bonusValue }}元补贴红包购买？</p>
            <img :src="goodsInfo.pictUrl" alt="">
            <van-button @click="handleDialogClick('buy')">领补贴购买</van-button>
            <p @click="handleCloseDialog('buy')">我再想想</p>
          </div>
        </div>
        <div v-else-if="dialogType == 'zero'" class="wrapper">
          <div class="free">
            <div class="activity-info">
              <p class="activity-title">你还有1次免费领机会未使用</p>
              <p>好物免费领，0元包邮到家</p>
            </div>
            <div class="activity-pic">
              <img src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/purchase_dialog_zero.png"/>
            </div>
            <van-button @click="handleNewForFree">现在就去领取</van-button>
            <p class="leave-button" @click="handleCloseDialog('zero')">我不要了，残忍离开</p>
          </div>
        </div>
        <div v-else-if="dialogType == 'invite'" class="wrapper">
          <div class="invite">
            <p class="title">再邀请{{ currentInvite }}名好友<br>红包膨胀{{ currentInvite * 50 }}元</p>
            <p>开通{{ currentZoneValue }}元专区商品补贴资格</p>
            <img src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/cash_invite.gif" alt="">
            <van-button @click="handleDialogClick('invite')">立即分享 膨胀红包</van-button>
          </div>
          <div class="lottery-close">
            <van-icon name="close" @click="handleCloseDialog('invite')"/>
          </div>
        </div>
        <div v-else-if="dialogType == 'cash'" class="cash-dialog">
          <img class="title" :src="`https://cdn.cailu88.com/jingxuanshi/phoneRecharge/cash_dialog_${cashTitle}.png`">
          <img v-if="cashTitle == 'dilate'" src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/purchase_dilate_packet.png">
          <img v-else src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/purchase_dilate_50.png" alt="">
          <div class="inner">
            <div v-if="cashTitle == 'dilate'">
              <p class="dilate-desc">支付1元，至多膨胀1000倍</p>
            </div>
            <p v-else class="invite-desc">每邀请1名好友<br>红包膨胀50元</p>
            <img :src="`https://cdn.cailu88.com/jingxuanshi/phoneRecharge/cash_button_${cashTitle}.png`"
                 @click="handleDialogClick('invite')">
            <p @click="handleCloseDialog('cash')">我不要了，残忍离开</p>
          </div>
        </div>
        <div v-else-if="dialogType == 'invite-jd'" class="cash-dialog-jd">
          <img class="title" src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/cash_dialog_invite.png">
          <p>邀请10名好友加入鲸选师<br>得专属千元补贴资格</p>
          <img src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/purchase_dilate_packet.png">
          <img @click="handleDialogClick('invite')" class="inner-button" src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/cash_button_invite.png">
          <div class="lottery-close">
            <van-icon name="close" @click="handleCloseDialog('invite')"/>
          </div>
        </div>
        <div v-else-if="dialogType == 'copy'" class="copy-share">
          <p>分享口令已复制至粘贴板</p>
          <van-button>去微信分享好友</van-button>
          <div class="lottery-close">
            <van-icon name="close" @click="handleCloseDialog('copy')"/>
          </div>
        </div>
      </van-dialog>
      <div class="fixed-top" @click="handleJumpJdCash">
        <img src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/cash_jd_float.gif" alt="">
      </div>
    </div>
    <div v-else class="cash-detail">
      <Head :headInfo="headInfo" @headBack="backCash"></Head>
      <template v-for="(item, key) in detailList">
        <div :key="key" class="list-wrap">
          <div class="info-left">
            <p>{{ item.bizTypeDesc }}</p>
            <p>{{ item.createTime }}</p>
          </div>
          <p v-if="item.incomeType == 1">+ {{ item.amount }}</p>
          <p v-else style="color: #FE4949">- {{ item.amount }}</p>
        </div>
      </template>
    </div>
    <Loading :click="click"></Loading>
  </div>
</template>
<script>
import Vue from 'vue'
import { Icon, Lazyload, Dialog, Button } from 'vant'
import Loading from '../../components/loading.vue'
import { timeFormat } from '@/utils/utils'
import Head from '../../components/fullWebviewHead.vue'
import baseUrl from '@/utils/baseUrl'

const api = require('@/utils/api').api

Vue.use(Icon)
Vue.use(Button)
Vue.use(Dialog)
Vue.use(Lazyload, {
  lazyComponent: true,
})

export default {
  name: 'tbCash',
  data () {
    return {
      currentZoneValue: '',
      currentInvite: '',
      // 礼金数额
      priceValue: '',
      // 礼金信息
      cashInfo: {},
      activityId: '',
      // 礼金专区
      zoneList: [],
      // 礼金商品
      goodsList: [],
      goodsInfo: {},
      // 礼金明细
      detailList: [],
      // 商品分页信息
      page: 0,
      pageSize: 10,
      totalCount: 0,
      // 加载中
      click: true,
      // 屏幕滚动
      isScroll: false,
      //弹窗
      dialogVisible: false,
      dialogType: '',
      // 礼金弹窗标题文字 invite/dilate/balance
      cashTitle: '',
      // 显示礼金明细
      showCashDetail: false,
      // 礼金明细页头
      headInfo: {}
    }
  },
  components: {
    Loading,
    Head
  },
  created () {
    this.zoneList = [
      {
        value: '',
        code: '',
        active: true
      },
      {
        value: '',
        code: '',
        active: false
      },
      {
        value: '',
        code: '',
        active: false
      },
      {
        value: '',
        code: '',
        active: false
      }
    ]
  },
  mounted () {
    if (this.$store.state.isAndroidApp) {
      this.$h5AppLink('updateStyle', {
        statusBarBgColor: '#FF6262',
        statusBarTextColor: 'white'
      })
    }
    this.backCash()
    this.handlePreBack()
    window.addEventListener('scroll', this.lazyLoading)
    // 通过$on,$once使用hook监听vue生命周期函数，进行监听销毁
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('scroll', this.lazyLoading)
    })
  },
  methods: {
    /*
    登录验证
     */
    isLogin () {
      let event = 'login'
      let param = {
        backUrl: `${baseUrl.pageUrl}/purchase`
      }
      this.$h5AppLink(event, param)
    },
    /*
    弹窗显示
    */
    handleDialogOperate (type) {
      if (type == 'cash') {
        this.dialogType = 'cash'
        this.cashTitle = 'invite'
        this.dialogVisible = true
      } else if (type == 'invite') {
        this.dialogType = 'invite'
      }
    },
    /*
    切换专区
     */
    handleChangeTab (index) {
      if (this.cashInfo.amount > this.zoneList[index].value) {
        this.page = 0
        this.zoneList.map(item => {
          item.active = false
        })
        this.zoneList[index].active = true
        this.activityId = this.zoneList[index].code
        this.handleGetGoodsList()
      } else if (this.cashInfo == {}) {
        this.dialogType = 'cash'
        this.cashTitle = 'dilate'
        this.dialogVisible = true
      } else {
        this.currentZoneValue = this.zoneList[index].value
        this.currentInvite = Math.ceil((this.currentZoneValue - this.cashInfo.amount) / 50)
        this.dialogType = 'invite'
        this.dialogVisible = true
      }
    },

    /*
    活动名称价格集合
    */
    handleGetPriceZone () {
      this.$axios(api.cashActivityInterval, {}).then(res => {
        let data = res.data.data
        if (data.length > 0) {
          data.map((item, index) => {
            if (item.priceMin == '30') {
              this.zoneList[0].code = item.id
            } else if (item.priceMin == '100') {
              this.zoneList[1].code = item.id
            } else if (item.priceMin == '200') {
              this.zoneList[2].code = item.id
            } else if (item.priceMin == '300') {
              this.zoneList[3].code = item.id
            }
            this.zoneList[index].value = item.priceMin
            this.zoneList[index].active = false
          })
          this.zoneList[0].active = true
          this.activityId = res.data.data[0].id
          this.handleGetGoodsList()
        }
      }).catch(err => {
        this.isLogin()
        console.info(err)
      })
    },

    /*
    图片下拉加载
    */
    lazyLoading () {
      // 获取 可视区高度`clientHeight、`滚动高度`scrollTop、`页面高度`scrollHeight
      let timer = null
      let scrollBox = ''
      let scrollTopGoods = document.documentElement.scrollTop || document.body.scrollTop
      let clientHeightGoods = document.documentElement.clientHeight
      let scrollHeightGoods = document.documentElement.scrollHeight
      this.isScroll = scrollTopGoods == 0 ? false : true
      if (scrollTopGoods + clientHeightGoods + 10 >= scrollHeightGoods) { // 滚动到底部，逻辑代码
        //事件处理
        if (this.goodsList.length < this.totalCount && this.totalCount !== 0) {
          this.handleGetGoodsList('up')
        }
      }
    },

    /*
    查看礼金余额
    */
    handleGetCashBalance () {
      this.$axios(api.cashBalance, {}).then(res => {
        this.cashInfo = res.data.data
        this.priceValue = res.data.data.balance
      }).catch(err => {
        if (err.data.code == 'FAILED') {
          this.dialogType = 'cash'
          this.cashTitle = 'dilate'
          this.dialogVisible = true
        }
      })
    },

    /*
    推荐商品
     */
    handleGetGoodsList (type) {
      if (!this.click && type) {
        return false
      }
      this.page++
      this.click = false
      this.$axios(api.cashGoodsList, {
        params: {
          page: this.page,
          pageSize: this.pageSize,
          // itemSource: 1,
          // clientType: 2,
          activityId: this.activityId
        }
      }).then(res => {
        res.data.data.data ? res.data.data.data.map(item => {
          //itemSource 1-tb , 2-jd , 3-pdd , 4-wph , 5-sn | userType: 0-tb 1-tm
          item.logo = `https://cdn.cailu88.com/jingxuanshi/pullNew/goods_logo_${item.itemSource === 3 ? 'pdd' : item.itemSource === 4 ? 'wph' : item.itemSource === 5 ? 'sn' : item.itemSource === 2 ? 'jd' : item.itemSource === 1 && item.userType == 0 ? 'tb' : item.itemSource === 1 && item.userType == 1 ? 'tm' : ''}.png`
        }) : []
        this.goodsList = type == 'up' ? [...this.goodsList, ...res.data.data.data] : res.data.data.data
        this.totalCount = res.data.data.totalCount
        this.click = true
      }).catch(err => {
        this.isLogin()
        console.info(err)
        this.click = true
      })
    },
    /*
    跳转商品详情页
     */
    handleJumpGoodsDetails (item) {
      this.goodsInfo = item
      if (this.priceValue > item.bonusValue) {
        this.dialogType = 'buy'
      } else {
        this.dialogType = 'cash'
        this.cashTitle = this.priceValue ? 'balance' : 'dilate'
      }
      this.dialogVisible = true
    },

    /*
    礼金页返回上一页
    */
    goBack () {
      this.$router.go(-1)
    },

    /*
      弹窗按钮点击事件
    */
    handleDialogClick (type) {
      if (!this.click) {
        return false
      }
      this.click = false
      switch (type) {
        case 'rule':
          this.click = true
          this.dialogType = type
          this.dialogVisible = true
          break
        case 'buy':
          this.$axios(api.cashSend, {
            params: {
              clientType: 2,
              activityId: this.goodsInfo.activityId,
              itemId: this.goodsInfo.itemId,
              itemSource: this.goodsInfo.itemSource,
              page: this.page
            }
          }).then(res => {
            this.click = true
            this.dialogVisible = false
            this.handleGetCashBalance()
            this.$h5AppLink('jumpTB', {
              url: res.data.data.spreadUrl
            })
          }).catch(err => {
            this.click = true
            console.info(err)
          })
          break
        case 'invite':
          if (this.cashTitle == 'invite' || this.cashTitle == 'balance' || this.dialogType == 'invite') {
            this.$axios(api.inviteInfo).then(res => {
              this.click = true
              this.$h5AppLink('share', {
                type: 'link',
                title: '快快帮我助力，解锁购物红包！',
                content: '快来帮我助力吧，吃喝玩乐的省钱方法都在这！',
                imageUrl: 'https://cdn.cailu88.com/jingxuanshi/share_hongbao_cash.jpg',
                url: res.data.data.shareUrl
              })
            }).catch(err => {
              console.info(err)
            })
          } else if (this.cashTitle == 'dilate') {
            this.$axios({
              url: api.wxPay,
              method: 'post',
              params: {
                tradeType: 4,
                bizType: 8,
                platform: 1
              }
            }).then(res => {
              this.click = true
              this.$router.push({ name: 'payConfirm' })
              window.location.href = res.data.data.mwebUrl
            }).catch(err => {
              this.click = true
              this.isLogin()
              console.info(err)
            })
          }
          break
      }
    },

    /*
    0元购
    */
    handleNewForFree () {
      this.$h5AppLink('openFreeActivity', {
        id: 2
      })
    },
    /*
    关闭弹窗
     */
    handleCloseDialog (type) {
      this.dialogVisible = false
    },
    /*
    跳转礼金明细
    */
    jumpCashDetails () {
      this.headInfo = {
        title: '补贴红包明细',
        fontColor: '#333333'
      }
      if (this.$store.state.isAndroidApp) {
        this.$h5AppLink('updateStyle', {
          statusBarBgColor: 'white',
          statusBarTextColor: 'black'
        })
      }
      this.showCashDetail = true
      this.handleGetDetails()
    },
    /*
    明细返回礼金页面
    */
    backCash () {
      this.headInfo = {
        title: '专属补贴红包',
        bgColor: '#FF6262',
        fontColor: '#FFFFFF',
        rightText: '规则'
      }
      if (this.$store.state.isAndroidApp) {
        this.$h5AppLink('updateStyle', {
          statusBarBgColor: '#FF6262',
          statusBarTextColor: 'white'
        })
      }
      this.showCashDetail = false
      this.page = 0
      this.handleGetPriceZone()
      this.handleGetCashBalance()
    },
    /*
    礼金明细
     */
    handleGetDetails () {
      this.$axios.post(api.cashDetails, {}).then(res => {
        let data = res.data.data.data
        data ? data.map(item => {
          item.createTime = item.createTime ? timeFormat(item.createTime) : ''
        }) : []
        this.detailList = data
      }).catch(err => {
        this.isLogin()
        console.info(err)
      })
    },
    /*
    跳转jd内购
    */
    handleJumpJdCash () {
      if (this.cashInfo.amount < 1000) {
        this.dialogType = 'invite-jd'
        this.dialogVisible = true
      } else {
        this.$router.push({
          name: 'jdCash',
          query: {
            token: localStorage.getItem('token')
          }
        })
      }
    },
    /*
    离开一元购，查询判断弹窗类型
    */
    handlePreBack () {
      this.$axios(api.drawPreBack, {}).then(res => {
        switch (res.data.data.bizType) {
          case 0:
            break
          case 1:
            break
          case 2:
            this.dialogType = 'zero'
            this.dialogVisible = true
            break
          case 3:
            this.dialogType = 'cash'
            this.cashTitle = 'dilate'
            this.dialogVisible = true
            break
        }
      }).catch(err => {
        console.info(err)
      })
    },
  }
}
</script>
<style lang="less" scoped>
/deep/ .van-dialog {
  overflow: visible;
}

.tb-cash {
  min-height: 100vh;
  background-color: #F9F9F9;

  .packet-fixed {
    position: fixed;
    top: 34px;
    right: 0;
    z-index: 999;

    & > img {
      width: 108px;
      height: 34px;
    }
  }

  .head-scroll {
    z-index: 99;
    position: fixed;
    top: 0;
    width: 375px;
    height: 72px;
    background: linear-gradient(180deg, #FF6262 0%, #FF3333 100%);

    p {
      margin-top: 18px;
      text-align: center;
      font-size: 15px;
      color: #FFFFFF;
      line-height: 21px;

      span {
        margin: 0 5px;
        font-size: 30px;
        font-weight: 800;
        color: #FFFFFF;
        line-height: 42px;
      }
    }
  }

  .head {
    position: relative;
    line-height: 0;

    & > img {
      width: 375px;
    }

    .head-content {
      position: absolute;
      top: 16px;
      left: 26px;

      .my-cash {
        display: flex;
        align-items: center;
        font-size: 15px;
        color: #FFFFFF;
        line-height: 21px;

        .van-icon {
          margin-left: 5px;
        }
      }

      .cash-price {
        margin-top: 4px;
        font-size: 18px;
        color: #FFFFFF;
        line-height: 25px;

        & > span {
          font-size: 60px;
          line-height: 84px;
        }
      }

      .cash-desc {
        margin-top: 6px;
        font-size: 13px;
        color: #FFFFFF;
        line-height: 18px;
      }
    }
  }

  .zone-fixed {
    position: fixed;
    top: 72px;
    width: 319px;
  }

  .cash-zone {
    height: 50px;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 28px;
    padding-right: 28px;
    z-index: 98;

    p {
      width: 52px;
    }

    .zone-active {
      text-align: center;

      & > p:first-child {
        font-size: 18px;
        font-weight: 500;
        color: #FE4949;
        line-height: 25px;
      }

      & > p:last-child {
        font-size: 12px;
        color: #FFFFFF;
        line-height: 17px;
        width: 49px;
        height: 18px;
        background: linear-gradient(135deg, #FF7A82 0%, #FF3333 100%);
        border-radius: 14px;
      }
    }

    .zone-item {
      text-align: center;

      & > p:first-child {
        font-size: 18px;
        font-weight: 500;
        color: #333333;
        line-height: 25px;
      }

      & > p:last-child {
        font-size: 12px;
        color: #999999;
        line-height: 17px;
      }

    }
  }

  .default {
    margin-top: 50px;
    text-align: center;

    & > img {
      width: 104px;
      height: 100px;
    }
  }

  .goods-list {
    padding-left: 12px;
    padding-right: 12px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 30px;

    .goods-wrap {
      margin-top: 16px;
      width: 170px;
      height: 300px;
      background: #FFFFFF;
      border-radius: 10px;

      & > img {
        width: 170px;
        height: 170px;
        border-radius: 10px;
      }

      .goods-title {
        display: flex;
        margin: 0 10px;

        & > p {
          & > img {
            position: relative;
            top: 2px;
            width: 30px;
            height: 16px
          }

          font-size: 13px;
          font-weight: 400;
          color: #000000;
          line-height: 18px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }

      .goods-discounts {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 20px;
        font-size: 12px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        /* color: #8b7f08; */
        line-height: 20px;
        margin: 3px 10px 0;

        .goods-coupon,
        .goods-cashback {
          color: #fff;
          background-color: #E44A59;
          border-radius: 2px;
        }

        .goods-cashback {
          padding: 0 4px;
          color: #FF3B3C;
          background: rgba(255, 59, 60, 0.1);
        }

        .goods-coupon {
          padding: 0 5px;
          margin-right: 8px;
          position: relative;
          text-align: center;
          min-width: 54px;
          height: 20px;
          background: url("https://cdn.cailu88.com/jingxuanshi/client_activity_coupon.png");
          background-size: cover;
          /* overflow: hidden; */
        }
      }

      .goods-price-volume {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 12px 10px 0;

        .price {
          color: #999;
          text-decoration: line-through;
        }
      }

      .goods-promotion-volume {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 10px;

        .money-symbol {
          font-size: 12px;
          font-weight: bold;
          color: #e44a59;
          line-height: 18px;
        }

        .goods-current-price {
          height: 30px;
          font-size: 20px;
          font-weight: bold;
          color: #e44a59;
          line-height: 30px;
        }
      }

      .goods-price-volume .price,
      .goods-promotion-volume .volume,
      .goods-price-volume .volume {
        height: 17px;
        font-size: 12px;
        line-height: 17px;
      }

      .goods-promotion-volume .volume,
      .goods-price-volume .volume {
        color: #999;
      }
    }
  }

  .wrapper {
    position: relative;
    width: 320px;
    background: #FFFFFF;
    border-radius: 16px;
    text-align: center;

    & > p {
      display: inline-block;
      margin-top: 20px;
      margin-bottom: 12px;
      font-size: 20px;
      font-weight: bold;
      color: #7F694E;
      line-height: 28px;
    }


    .content {
      display: inline-block;
      margin: 0 auto 10px;
      width: 272px;
      height: 355px;
      font-size: 15px;
      font-weight: 400;
      overflow-y: scroll;
      color: #333333;
      line-height: 25px;

      p {
        text-align: left;
      }
    }

    .lottery-close {
      position: absolute;
      bottom: -60px;
      left: 142px;

      .van-icon {
        font-size: 38px;
        color: #ffffff;
      }
    }

    .buy-confirm {
      display: inline-block;
      height: 365px;

      .title {
        margin-top: 20px;
        margin-bottom: 18px;
        font-size: 20px;
        font-weight: bold;
        color: #FE4949;
        line-height: 28px;
      }

      & > img {
        margin-bottom: 22px;
        width: 180px;
        height: 180px;
      }

      .van-button {
        margin-bottom: 14px;
        width: 260px;
        height: 50px;
        background: linear-gradient(135deg, #FF6262 0%, #FF3333 100%);
        box-shadow: 0px 2px 6px 0px rgba(232, 64, 0, 0.5);
        border-radius: 28px;
        font-size: 20px;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 50px;
        border: none;
      }

      & > p {
        font-size: 15px;
        color: #999999;
        line-height: 21px;
      }
    }

    .free {
      display: inline-block;
      height: 390px;

      .activity-info {
        margin-top: 17px;

        .activity-title {
          margin-top: 16px;
          margin-bottom: 6px;
          font-size: 20px;
          font-weight: bold;
          line-height: 28px;
        }

        p {
          font-size: 15px;
          color: #333333;
          line-height: 21px;
        }
      }

      .activity-pic {
        margin: 10px auto 15px;

        img {
          width: 180px;
          height: 200px;
        }
      }

      .van-button {
        width: 260px;
        height: 50px;
        background: linear-gradient(135deg, #FF6262 0%, #FF3333 100%);
        box-shadow: 0px 2px 6px 0px rgba(232, 64, 0, 0.5);
        border-radius: 28px;
        font-size: 20px;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 50px;
        border: none;
      }

      .leave-button {
        margin-top: 11px;
        font-size: 15px;
        color: #999999;
        line-height: 21px;
      }
    }

    .invite {
      height: 396px;
      display: inline-block;

      .title {
        margin-top: 13px;
        font-size: 20px;
        font-weight: bold;
        color: #333333;
        line-height: 28px;
      }

      & > p {
        margin-top: 10px;
        font-size: 15px;
        color: #333333;
        line-height: 21px;
      }

      & > img {
        margin-top: 10px;
        margin-bottom: 18px;
        width: 180px;
        height: 200px;
      }

      .van-button {
        width: 260px;
        height: 50px;
        background: linear-gradient(135deg, #FF6262 0%, #FF3333 100%);
        box-shadow: 0px 2px 6px 0px rgba(232, 64, 0, 0.5);
        border-radius: 28px;
        font-size: 20px;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 28px;
        border: none;
      }
    }
  }

  .cash-dialog {
    position: relative;
    text-align: center;

    & > img {
      margin-top: -10px;
      width: 300px;
    }

    .title {
      width: 180px;
    }

    .inner {
      position: absolute;
      top: 90px;
      left: 46px;

      .invite-desc {
        margin-bottom: 164px;
        font-size: 18px;
        color: #C36202;
        line-height: 25px;
      }

      .dilate-desc {
        margin-bottom: 160px;
        font-size: 15px;
        color: #C36202;
        line-height: 21px;
      }

      & > img {
        margin-bottom: 10px;
        width: 228px;
        height: 53px;
      }

      & > p {
        font-size: 13px;
        color: #FFFFFF;
        line-height: 18px;
      }
    }
  }

  .cash-dialog-jd{
    position: relative;
    text-align: center;
    .title {
      margin-bottom: -10px;
      width: 180px;
    }
    & > p {
      margin-bottom: -4px;
      font-size: 18px;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 25px;
    }
    & > img{
      width: 300px;
    }
    .inner-button{
      position: absolute;
      bottom: 45px;
      left: 44px;
      width: 228px;
    }
    .lottery-close {
      position: absolute;
      bottom: -60px;
      left: 142px;

      .van-icon {
        font-size: 38px;
        color: #ffffff;
      }
    }
  }

  .copy-share {
    position: relative;
    display: inline-block;
    width: 320px;
    height: 156px;
    background: #FFFFFF;
    border-radius: 16px;
    text-align: center;

    & > p {
      margin-top: 30px;
      margin-bottom: 18px;
      font-size: 20px;
      font-weight: bold;
      color: #FE4949;
      line-height: 28px;
    }

    .van-button {
      width: 260px;
      height: 50px;
      background: linear-gradient(135deg, #FF6262 0%, #FF3333 100%);
      box-shadow: 0px 2px 6px 0px rgba(232, 64, 0, 0.5);
      border-radius: 28px;
      font-size: 20px;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 50px;
      border: none;
    }

    .lottery-close {
      position: absolute;
      bottom: -60px;
      left: 142px;

      .van-icon {
        font-size: 38px;
        color: #ffffff;
      }
    }
  }

  .fixed-top {
    position: fixed;
    bottom: 96px;
    right: 12px;

    & > img {
      width: 50px;
      height: 50px;
    }
  }
}

.cash-detail {
  width: 375px;
  min-height: 100vh;
  background: #F9F9F9;

  .list-wrap {
    margin: 0 auto 8px;
    width: 360px;
    height: 80px;
    background: #FFFFFF;
    border-radius: 10px;
  }

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;

    .info-left {
      margin-left: 16px;

      & > p:first-child {
        font-size: 15px;
        font-weight: bold;
        color: #333333;
        line-height: 21px;
      }

      & > p:last-child {
        font-size: 13px;
        color: #999999;
        line-height: 18px;
      }
    }

    & > p {
      margin-right: 16px;
      font-size: 20px;
      font-weight: 800;
      color: #333333;
      line-height: 28px;
    }
  }
}
</style>
